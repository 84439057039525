import React from 'react'
import { Link } from 'gatsby'
import { LayoutWithImage } from '../../layouts'
import { CONTENT } from '../../content'
import Seo from '../../components/seo'
import Map from '../../components/Map'
import BreadcrumbList from '../../components/BreadcrumbList/BreadcrumbList'
import BreadcrumbItem from '../../components/BreadcrumbList/BreadcrumbItem'

const LeCabinet = () => (
  <LayoutWithImage>
    <>
      <Seo
        title="Emilie Pfister - Le cabinet"
        description={`Plus d'informations sur le cabinet de Dieulouard (54380) où se déroulent mes séances. ${CONTENT.CONTACT.GENERAL.content[0].toLocaleLowerCase()}`}
      />
      <section id="le-cabinet">
        <header>
          <h1>{CONTENT.OFFICE.title}</h1>
        </header>

        <div>
          {CONTENT.OFFICE.content.map((content, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
          ))}
        </div>
      </section>

      <Map />

      <BreadcrumbList>
        <>
          <BreadcrumbItem position={1}>
            <Link className="link" itemProp="item" to="/">
              <span itemProp="name">Accueil</span>
            </Link>
          </BreadcrumbItem>
          ›
          <BreadcrumbItem position={2}>
            <Link className="link" itemProp="item" to="/presentation">
              <span itemProp="name">Présentation</span>
            </Link>
          </BreadcrumbItem>
          ›
          <BreadcrumbItem position={3}>
            <span itemProp="name">Le Cabinet</span>
          </BreadcrumbItem>
        </>
      </BreadcrumbList>
    </>
  </LayoutWithImage>
)

export default LeCabinet
